import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "src", "srcset", "srcSetSettingImg", "mobileImageUrl", "sizes", "placeholderSrc", "alt", "title", "styleType", "inheritedStyles", "imageOptimisationType", "isHero", "isLessThanDesktop", "isGreaterThanPhone", "mobileCardBackgroundColor", "donotLazyLoad", "priority", "blurDataURL", "contentType", "width", "height", "removePlaceholder"];
var __jsx = React.createElement;
/* eslint-disable prettier/prettier */
/**
 * Represents a Image Atom.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import constants from '../../../constants/globalConstants';
import { getImageSrcSet } from '../../../utils/utilityFunction';
import styles, { ImageContainer } from './Image.style';
import Image from 'next/future/image';
import { replaceSpaceInUrl } from '../../../utils';

/**
 * Button component.
 * @param {string} className - Class of the tag.
 * @param {string} src - source of image tag.
 * @param {string} inheritedStyles - inherited styles.
 * @param {string} srcset - source set of image tag.
 * @param srcSetSettingImg
 * @param mobileImageUrl
 * @param {string} placeholderSrc - place holder source of image tag.
 * @param {union type} styleType - heroCarousel image
 * @param {string} alt - alternate text of image tag.
 * @param {string} sizes - different sizes.
 * @param title
 * @param imageOptimisationType
 * @param isHero
 * @param isLessThanDesktop
 * @param isGreaterThanPhone
 * @param mobileCardBackgroundColor
 * @param donotLazyLoad
 * @param priority
 * @param contentType
 * @param width
 * @param height
 * @param other
 * @param { boolean } removePlaceholder
 */
var ImageBlock = function ImageBlock(_ref) {
  var className = _ref.className,
    src = _ref.src,
    srcset = _ref.srcset,
    srcSetSettingImg = _ref.srcSetSettingImg,
    mobileImageUrl = _ref.mobileImageUrl,
    sizes = _ref.sizes,
    placeholderSrc = _ref.placeholderSrc,
    alt = _ref.alt,
    title = _ref.title,
    styleType = _ref.styleType,
    inheritedStyles = _ref.inheritedStyles,
    imageOptimisationType = _ref.imageOptimisationType,
    isHero = _ref.isHero,
    isLessThanDesktop = _ref.isLessThanDesktop,
    isGreaterThanPhone = _ref.isGreaterThanPhone,
    mobileCardBackgroundColor = _ref.mobileCardBackgroundColor,
    donotLazyLoad = _ref.donotLazyLoad,
    priority = _ref.priority,
    blurDataURL = _ref.blurDataURL,
    contentType = _ref.contentType,
    width = _ref.width,
    height = _ref.height,
    removePlaceholder = _ref.removePlaceholder,
    other = _objectWithoutProperties(_ref, _excluded);
  var imageSrcSetObj = srcSetSettingImg && src ? getImageSrcSet(srcSetSettingImg, src, mobileImageUrl, imageOptimisationType) : null;
  var renderSources = function renderSources() {
    var picSources2 = Object.keys(constants.srcSetVariations).reduce(function (acc, size) {
      return acc = [].concat(_toConsumableArray(acc), [__jsx("source", {
        key: "source-".concat(alt, "-").concat(size),
        srcSet: "".concat(imageSrcSetObj[size], ", ").concat(imageSrcSetObj[size + 'Retina'], " 2x "),
        type: "image/webp",
        media: "(min-width: ".concat(constants.srcSetVariations[size], "px)")
      })]);
    }, [__jsx("source", {
      key: "source-".concat(alt, "-mobile-max-width"),
      srcSet: "".concat(imageSrcSetObj.mobile, ", ").concat(imageSrcSetObj.mobileRetina, " 2x "),
      type: "image/webp",
      media: "(max-width: ".concat(constants.srcSetVariations.mobile, "px)")
    })]);
    return picSources2.map(function (source) {
      return source;
    });
  };

  // check whether to render picture tag or img tag
  var renderPicture = !!(srcSetSettingImg && srcSetSettingImg.imageRenditions);
  // preapre image src whether picture or img tag
  var imgSrc = renderPicture ? imageSrcSetObj ? "".concat(imageSrcSetObj.desktop, ", ").concat(imageSrcSetObj.desktopRetina) : placeholderSrc : src || placeholderSrc;
  var sizeParameters = width && height ? {
    width: width,
    height: height
  } : {
    fill: true,
    style: {
      objectFit: 'cover',
      objectPosition: 'center center'
    }
  };
  return mobileCardBackgroundColor && !isGreaterThanPhone ? __jsx("div", {
    className: "replace-img-with-color"
  }) : __jsx(ImageContainer, {
    className: "image-container"
  }, src && __jsx("picture", null, imageSrcSetObj && renderSources(), __jsx(Image, _extends({
    className: "nva-image ".concat(className),
    alt: alt || '',
    title: isHero ? title : (title === null || title === void 0 ? void 0 : title.trim()) || alt || '',
    src: replaceSpaceInUrl(imgSrc),
    placeholder: blurDataURL ? "blur" : "empty",
    blurDataURL: blurDataURL,
    unoptimized: !!imageSrcSetObj,
    priority: priority,
    fetchpriority: priority ? 'high' : null
  }, sizeParameters, other))));
};
ImageBlock.defaultProps = {
  srcset: '',
  sizes: '100vw',
  placeholderSrc: '',
  inheritedStyles: '',
  mobileImageUrl: '',
  styleType: '',
  alt: '',
  title: '',
  imageOptimisationType: '',
  blurDataURL: null,
  isHero: false,
  donotLazyLoad: false,
  priority: false
};
export default styled(ImageBlock).withConfig({
  componentId: "sc-eu5zgc-0"
})(["", ";"], styles);
export { ImageBlock as ImageVanilla };