import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Location(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 12 16"
  }, props), __jsx("path", {
    d: "M6 8.834a2.847 2.847 0 112.851-2.846A2.79 2.79 0 016 8.834zM6 0a5.955 5.955 0 00-6 5.988C0 10.9 6 16 6 16s6-5.1 6-10.012A5.835 5.835 0 006 0z",
    fill: "#6e96ab"
  }));
}
export default Location;