import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["&.sub-menu{display:none;position:initial;z-index:3;background-color:var(--sc-header--bg-color,", ");top:calc(100% + 9px);left:0;padding-top:18px;&.open{display:flex;flex-direction:column;@media (min-width:", "){flex-direction:row;}}@media (min-width:", "){padding-top:0;position:absolute;border-top:1px solid var(--sc-header--border-color,", ");}@media (min-width:", "){top:calc(100% + 16px);left:0;}}.sub-menu{&-list{padding:0 0 5px;@media (min-width:", "){padding:28px 0;min-width:220px;}}&-heading{color:var(--sc-header--primary-color,", ");letter-spacing:var(--sc-letter-spacing-secondary-link,", ");line-height:var(--sc-line-height-secondary-link,", ");padding:25px 0 18px;@media (min-width:", "){padding:25px 40px 13px;}}&-link{display:block;width:100%;color:var(--sc-header--primary-color,", ");letter-spacing:var(--sc-letter-spacing-primary-link,", ");line-height:var(--sc-line-height-primary-link,", ");padding:12px 0;&:hover{text-decoration:underline;}&:last-of-type{padding-bottom:5px;}@media (min-width:", "){padding:12px 40px;}}}"], function (props) {
  var _props$theme, _props$theme$header;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$header = _props$theme.header) === null || _props$theme$header === void 0 ? void 0 : _props$theme$header.bgColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme2, _props$theme2$header;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$header = _props$theme2.header) === null || _props$theme2$header === void 0 ? void 0 : _props$theme2$header.borderColor;
}, breakPoints.desktopM, breakPoints.desktop, function (props) {
  var _props$theme3, _props$theme3$header;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$header = _props$theme3.header) === null || _props$theme3$header === void 0 ? void 0 : _props$theme3$header.primaryColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.letterSpacingSecondaryLink;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.lineHeightSecondaryLink;
}, breakPoints.desktop, function (props) {
  var _props$theme6, _props$theme6$header;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$header = _props$theme6.header) === null || _props$theme6$header === void 0 ? void 0 : _props$theme6$header.primaryColor;
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.letterSpacingPrimaryLink;
}, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.lineHeightPrimaryLink;
}, breakPoints.desktop);
export default styles;