import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Comment(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 15.9 14"
  }, props), __jsx("path", {
    d: "M6.3 12.6c.5.1 1.1.1 1.6.1 4.4 0 8-2.8 8-6.3S12.3 0 8 0 0 2.8 0 6.3c.1 2.3 1.4 4.3 3.5 5.2L2.3 14l4-1.4z"
  }));
}
export default Comment;