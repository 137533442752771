/**
 * Manages styles of Image Atom.
 * @author kkum
 */
import styled, { css } from 'styled-components';
var imageStyles = css(["max-width:100%;width:", ";height:", ";margin-bottom:0;display:block;"], function (props) {
  return props.width ? "".concat(props.width, "px") : '100%';
}, function (props) {
  return props.height ? "".concat(props.height, "px") : 'auto';
});
export var ImageContainer = styled.div.withConfig({
  componentId: "sc-1mj8z02-0"
})(["position:relative;", ""], function (props) {
  return (!props.width || !props.height).toString() ? "\n  width: 100%;" : '';
});
export default imageStyles;